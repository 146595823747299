<template>
  <div class="main">
    <div class="main-top">
      <img @click="goBack" src="@/assets/image/arrow-left.png" />
      <span>支付宝设置</span>
    </div>
    <div class="content-fa" v-if="userInfo">
      <div class="main-content">
        <input v-model="realName" placeholder="姓名" />
        <input v-model="alipay" placeholder="支付宝账户" />
        <div class="tips">
          温馨提示：一个支付宝只能对应一个账号，本次完善后将不允许修改，请填写您的常用支付宝账号。
        </div>
      </div>
      <div
        :class="[
          'btn2',
          { 'btn-opacity': userInfo.alipay && userInfo.alipay !== '-' }
        ]"
        @click="update"
      >
        确认修改
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userInfo: null,
      realName: "",
      alipay: ""
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    LogOut() {
      localStorage.clear();
      this.$router.push("/mobile");
    },
    update() {
      if (this.userInfo.alipay && this.userInfo.alipay !== "-") {
        this.$dialog.alert({
          title: "提示",
          message: "支付宝信息已完善，无需重复提交！"
        });
        return;
      }
      let userid = localStorage.getItem("userid");
      this.$http
        .post(`/api/v1/Accounts/${userid}/Alipay`, null, {
          params: {
            realName: this.realName,
            alipay: this.alipay,
            id: userid
          }
        })
        .then(async res => {
          if (res.status === 200) {
            this.$toast("修改成功");
            let userid = localStorage.getItem("userid");
            const res = await this.$http.get(`/api/v1/Accounts/${userid}`, {
              params: {
                id: userid
              }
            });
            if (res.status === 200) {
              localStorage.setItem("userInfo", JSON.stringify(res.data));
            }
          } else {
            console.log("res", res);
            this.$toast(res.statusText);
          }
        });
    }
  },
  async created() {
    if (localStorage.getItem("userid")) {
      let userid = localStorage.getItem("userid");
      const res = await this.$http.get(`/api/v1/Accounts/${userid}`, {
        params: {
          id: userid
        }
      });
      if (res.status === 200) {
        localStorage.setItem("userInfo", JSON.stringify(res.data));
      }
    } else {
      this.$router.push("/mobile");
    }

    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.realName = this.userInfo.name === "-" ? null : this.userInfo.name;
    this.alipay = this.userInfo.alipay === "-" ? null : this.userInfo.alipay;
    console.log("11111", this.userInfo);
  }
};
</script>
<style lang="scss" scoped>
.main {
  min-height: calc(100vh - 40px);
  width: 100vw;
  background-color: #f8f8fa;
  position: relative;
  padding-top: 40px;
  .main-top {
    position: fixed;
    top: 0;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #232323;
    font-size: 16px;
    font-weight: bolder;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    background-color: #fff;
    img {
      width: 20px;
      position: absolute;
      left: 0;
      margin-left: 15px;
    }
  }
  .main-content {
    padding: 0 15px;
    box-sizing: border-box;
    width: 100%;
    input {
      margin-top: 14px;
      width: 100%;
      height: 46px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: none;
      outline: none;
      padding: 0 12px;
      box-sizing: border-box;
      font-size: 14px;
    }
    .tips {
      color: #fa5151;
      font-size: 16px;
      margin-top: 20px;
    }
  }
  .content-fa {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 40px);
    .btn2 {
      width: calc(100% - 30px);
      height: 46px;
      background: linear-gradient(138deg, #ffa200 0%, #ff6017 100%);
      border-radius: 25px 25px 25px 25px;
      text-align: center;
      line-height: 46px;
      color: #ffffff;
      font-size: 18px;
      box-sizing: border-box;
      margin: 40px 15px;
    }
    .btn-opacity {
      opacity: 0.6;
    }
  }
}
</style>
